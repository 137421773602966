body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mainContainer {
  margin: auto;
  width: 100%;
  max-width: 440px;
}

.main {
  padding: 20px;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  background: none;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  width: 24px;
  fill: rgb(238, 174, 202);
  margin: 0px 16px;
}